import { MdLanguage, MdLibraryBooks, MdOutlineComputer, MdOutlinePeople, MdRadio, MdShare, MdTv } from 'react-icons/md';
import { TbHeartRateMonitor } from 'react-icons/tb';
import { SidebarMenuItemProps } from 'shared/interfaces/utils/ISidebar';

import { UserOutlined } from '@ant-design/icons';
import { ROUTES } from './ROUTES';
import {
  parent_access_admin,
  parent_access_mailer_monitoring,
  parent_access_radio,
  parent_access_tv,
} from './SIDEBAR_HELPERS';

interface Props {
  current_user_role: string[];
  role: string;
}

const filterByRoleAccess = (list: SidebarMenuItemProps[], roles: string[]) => {
  return list
    .map((item) => {
      if (item?.children)
        return {
          ...item,
          children: filterByRoleAccess(item.children, roles),
        };

      return item;
    })
    .filter((item) => roles.includes(item.access || ''));
};

export const SIDEBAR_MENU_LIST = ({ current_user_role, role }: Props): SidebarMenuItemProps[] => {
  const ITEM_LIST = [
    {
      title: 'MEDIAWATCH',
      isTitle: true,
      titleColor: '#736cca',
      titleCollapsed: 'MW',
      access: 'access_clients',
    },
    {
      title: 'Admin',
      icon: <MdOutlineComputer />,
      children: [
        role !== 'Operations' && role !== 'BD/CS'
          ? {
              title: 'Users',
              to: ROUTES.ADMIN.USERS.LIST,
              access: 'access_admin_users_management',
            }
          : {
              title: 'Users',
              to: ROUTES.ADMIN.USERS.LIST,
            },
        {
          title: 'Clients',
          to: ROUTES.ADMIN.CLIENTS.LIST,
          access: 'access_clients',
        },
        {
          title: 'Mailer Templates',
          to: ROUTES.ADMIN.MAILER.TEMPLATES.LIST,
          access: 'access_mailer_setup',
        },
        {
          title: 'Corpus',
          children: [
            {
              title: 'Client',
              to: ROUTES.ADMIN.CORPUS.CLIENT,
              access: 'access_corpus',
            },
            {
              title: 'Keyword',
              to: ROUTES.ADMIN.CORPUS.KEYWORD,
              access: 'access_corpus',
            },
          ],
          access: 'access_corpus',
        },
      ],
      access: parent_access_admin({ current_user_role }),
    },
    {
      title: 'Authors',
      icon: <MdOutlinePeople />,
      children: [
        {
          title: 'Overview (List)',
          to: ROUTES.AUTHORS.OVERVIEW.LIST,
          access: 'access_authors',
        },
      ],
      access: 'access_authors',
    },
    {
      title: 'Monitoring',
      icon: <TbHeartRateMonitor />,
      children: [
        {
          title: 'Corpus',
          to: ROUTES.MONITORING.CORPUS,
          access: 'access_corpus',
        },
        {
          title: 'FB Downloader',
          to: ROUTES.MONITORING.FB_DOWNLOADER.LIST,
          access: 'access_fb_download_monitoring',
        },
        {
          title: 'Mailer',
          to: ROUTES.MONITORING.MAILER,
          access: 'access_mailer_monitoring',
        },
        {
          title: 'Reports',
          to: ROUTES.MONITORING.REPORTS,
          access: 'access_mailer_monitoring',
        },
        {
          title: 'AV Machines',
          to: ROUTES.MONITORING.AV_MACHINES.LIST,
          access: 'access_av_machine_monitoring',
        },
        {
          title: 'Keywords',
          to: ROUTES.MONITORING.KEYWORDS,
          access: 'access_keywords_monitoring',
        },
        {
          title: 'Activity Tracker',
          to: ROUTES.MONITORING.ACTIVITY_TRACKER,
          access: 'access_activity_tracker',
        },
      ],
      access: parent_access_mailer_monitoring({ current_user_role }),
    },
    {
      title: 'Online News & Blogs',
      icon: <MdLanguage />,
      to: ROUTES.ONLINE.LIST,
      access: 'access_online_news_blogs',
    },
    {
      title: 'Print',
      icon: <MdRadio />,
      children: [
        {
          title: 'Download',
          to: ROUTES.PRINT.DOWNLOAD,
          access: 'access_print',
        },
        {
          title: 'Print Media',
          to: ROUTES.PRINT.PRINTMEDIA.LIST,
          access: 'access_print',
        },
        {
          title: 'Print Ads',
          to: ROUTES.PRINT.ADS.LIST,
          access: 'access_print',
        },
      ],
      access: 'access_print',
    },
    {
      title: 'Publications',
      icon: <MdLibraryBooks />,
      children: [
        {
          title: 'Overview (List)',
          to: ROUTES.PUBLICATIONS.LIST.LIST,
          access: 'access_publishers',
        },
        // {
        //   title: 'Time Slots',
        //   to: ROUTES.PUBLICATIONS.TIME_SLOTS,
        //   access: 'access_publishers',
        // },
      ],
      access: 'access_publishers',
    },
    {
      title: 'Radio',
      icon: <MdRadio />,
      children: [
        {
          title: 'Station Timeslot',
          to: ROUTES.RADIO.TIMESLOT.LIST,
          access: 'access_radio',
        },
        {
          title: 'Program List',
          to: ROUTES.RADIO.PROGRAMLIST.LIST,
          access: 'access_radio',
        },
        // new add
        {
          title: 'Program Setup',
          to: ROUTES.RADIO.PROGRAMSETUP.LIST,
          access: 'access_automation_tv_setup',
          parent_access_radio,
        },
        {
          title: 'Radio Clips',
          to: ROUTES.RADIO.RADIOCLIPS.LIST,
          access: 'access_radio',
        },
        {
          title: 'Radio Ads',
          to: ROUTES.RADIO.RADIOAD.LIST,
          access: 'access_radio',
        },
      ],
      access: parent_access_radio({ current_user_role }),
    },
    {
      title: 'TV',
      icon: <MdTv />,
      children: [
        {
          title: 'Control',
          to: ROUTES.TV.CONTROL.LIST,
          access: 'access_tv',
        },
        {
          title: 'Channel Timeslot',
          to: ROUTES.TV.TIMESLOT.LIST,
          access: 'access_tv',
        },
        {
          title: 'Program Setup',
          to: ROUTES.TV.PROGRAMSETUP.LIST,
          access: 'access_automation_tv_setup',
        },
        {
          title: 'Program List',
          to: ROUTES.TV.PROGRAMLIST.LIST,
          access: 'access_tv_program',
        },
        {
          title: 'Video Clips',
          to: ROUTES.TV.TVCLIPS.LIST,
          access: 'access_tv',
        },
        {
          title: 'TV Ads',
          to: ROUTES.TV.TVADS.LIST,
          access: 'access_tv',
        },
      ],
      access: parent_access_tv({ current_user_role }),
    },

    {
      title: 'Website Garden',
      icon: <MdLanguage />,
      to: ROUTES.WEBSITEGARDEN.LIST.LIST,
      access: 'access_management_of_website_garden',
    },
    {
      title: 'Web Syndication',
      icon: <MdLanguage />,
      to: ROUTES.API_FEED.OVERVIEW.OVERVIEW,
      access: 'access_clients',
    },
    {
      title: 'SHAREDVIEW',
      isTitle: true,
      titleColor: '#4288f1',
      titleCollapsed: 'SV',
      access: 'access_clients',
    },
    {
      title: 'Influencers',
      icon: <UserOutlined />,
      to: ROUTES.INFLUENCERS.LIST.LIST,
      access: 'access_influencers',
    },
    {
      title: 'Social Media',
      icon: <MdShare />,
      children: [
        {
          title: 'Posts',
          to: ROUTES.SOCIALMEDIA.POSTS.LIST,
          access: 'access_social_media',
        },
      ],
      access: 'access_social_media',
    },
  ];

  return filterByRoleAccess(ITEM_LIST, current_user_role);
  // return ITEM_LIST;
};
